import { AxiosResponse } from "axios";
import qs from "qs";
// import { User } from "../typings/api";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import APIService from "./api.service";
import AuthService from "./auth.service";

class UsersService extends APIService<ClearERCUser> {
    constructor() {
        super("users");
    }
    getOne(id: string) {
        return this.getURL("/users/" + id);
    }

    getAllWithCredentials() {
        return this.getURL(this.endpoint + "?filter=" + encodeURIComponent("[include][][relation]=UserCredentials"));
    }

    getAllWithRoles() {
        return this.getURL(this.endpoint + "?filter=" + encodeURIComponent("[include][][relation]=Roles"));
    }

    getVerificationToken(id: string, token: string) {
        return this.getURL("/users/verification/" + id + "/" + token);
    }

    resendVerificationToken(id: string) {
        return this.getURL("/users/resend-verification/" + id + "/");
    }

    resetPassword(email: string) {
        return this.postURL("/users/reset/" + encodeURIComponent(email), {});
    }

    resetPasswordComplete(id: string, token: string, password: string) {
        return this.postURL("/users/reset-do/" + encodeURIComponent(id) + "/" + encodeURIComponent(token), {
            password: password,
        });
    }

    async getAll(params?: {
        filters: { role: "Affiliate" | "Accountant" | "Closer" | undefined };
    }): Promise<void | AxiosResponse<ClearERCUser[]>> {
        const canListAllUsers = await AuthService.canIAccess("LIST_ALL_USERS");
        const queryString = params?.filters.role ? qs.stringify({ role: params.filters.role }) : "";
        const url = (canListAllUsers ? `users` : `my-users`) + (queryString ? `?${queryString}` : "");
        console.info({ url, params });
        return super.getURL(url) as Promise<void | AxiosResponse<ClearERCUser[]>>;
    }
}

export default new UsersService();
